import { ReactNode, useEffect, useState } from 'react';
import {
    BrButton,
    BrCard,
    BrTag,
    Icon,
    Row,
    Typography
} from '@govbr-ds/react-components';
import lang from '../../lang';
import { Consentimento } from '../../service/consentimentosService';
import { ConsentHistoryProps, getTagColor } from '.';
import { HistoricoConsentimento } from '../../types/dtos';
import { StatusConsentimento } from '../../types/enums';
import '../../style/info.css';
import '../../style/list.css';
import stringUtils from '../../utils/stringUtils';
import ConsentHistory from './ConsentHistory';

// ---------- Interface ----------

interface ConsentimentoInfoProps {
    label: string;
    value?: string;
    icon?: string;
    children?: ReactNode;
}

interface StatusButtonProps {
    idConsentimento: string;
    onClick: (status: StatusConsentimento) => void;
}
interface ConsentCardFooterProps {
    status: string;
    idConsentimento: string;
    onUpdate: (status: StatusConsentimento) => void;
}

interface ConsentCardProps {
    consentimento: Consentimento;
    onUpdate: (status: StatusConsentimento) => void;
}

// ------------ Render ------------

const ConsentInfo = ({
    label,
    value,
    icon,
    children
}: ConsentimentoInfoProps) => {
    return (
        <div className={'info-panel'}>
            <Typography htmlElement="p" weight="bold" className="mb-1n">
                {label}
            </Typography>
            {icon && <Icon icon={icon} />}
            {value ? <span className={'info-text'}>{value}</span> : children}
        </div>
    );
};

const RevokeButton = ({ onClick }: StatusButtonProps) => (
    <BrButton secondary onClick={() => onClick(StatusConsentimento.REVOGADO)}>
        {lang.tr('revogar')}
    </BrButton>
);

const DeclineButton = ({ onClick }: StatusButtonProps) => (
    <BrButton secondary onClick={() => onClick(StatusConsentimento.NEGADO)}>
        {lang.tr('negar')}
    </BrButton>
);

const ConsentButton = ({ onClick }: StatusButtonProps) => (
    <BrButton primary onClick={() => onClick(StatusConsentimento.ATIVO)}>
        {lang.tr('consentir')}
    </BrButton>
);

const ConsentCardFooter = ({
    status,
    idConsentimento,
    onUpdate
}: ConsentCardFooterProps) => {
    switch (status) {
        case StatusConsentimento.ATIVO:
            return (
                <Row className="card-footer" justifyContent="center">
                    <RevokeButton
                        idConsentimento={idConsentimento}
                        onClick={onUpdate}
                    />
                </Row>
            );

        case StatusConsentimento.PENDENTE:
            return (
                <Row className="card-footer" justifyContent="around">
                    <DeclineButton
                        idConsentimento={idConsentimento}
                        onClick={onUpdate}
                    />
                    <ConsentButton
                        idConsentimento={idConsentimento}
                        onClick={onUpdate}
                    />
                </Row>
            );

        default:
            return <></>;
    }
};

const ConsentCard = ({ consentimento, onUpdate }: ConsentCardProps) => {
    const [history, setHistory] = useState<ConsentHistoryProps[]>();

    useEffect(() => {

    }, [history]);

    useEffect(() => {
        consentHistoryMap();
    }, [consentimento]); // eslint-disable-line react-hooks/exhaustive-deps

    const consentHistoryMap = () => {
        let historico = consentimento.historico ?? []
        if (historico.length === 0) {
            return null
        }

        let orderedHistory = historico ? historico.sort((a, b) => b.dataHora.localeCompare(a.dataHora)) : []
        let map: Map<string, HistoricoConsentimento[]> = new Map();

        for (let item of orderedHistory) {
            let key = stringUtils.getDateFromDatetime(item.dataHora);
            if (!map.has(key)) {
                map.set(key, []);
            }
            map.get(key)?.push(item);
        }

        let list: ConsentHistoryProps[] = [];
        map.forEach((values, keys) => {
            list.push({date: keys, entries: values})
        });

        setHistory(list);
    }

    // ---------- Render ----------

    const renderHistory = () => {
        return history?.map((entry) => {
            return <ConsentHistory date={entry.date} entries={entry.entries} key={entry.date}/>
        }) ?? null
    }

    return (
        <BrCard style={{ padding: '1rem' }}>
            <Row className="card-header" justifyContent="between">
                <Typography weight="bold" size="up-02">
                    {consentimento.controlador.nome}
                </Typography>

                <BrTag
                    color={getTagColor(consentimento.status)}
                    value={lang.tr(consentimento.status)}
                    type="text"
                />
            </Row>
            <Row className="card-content">
                <div style={{ width: '100%', marginBottom: '15px' }}>
                    <ConsentInfo
                        label={lang.tr('categoria')}
                        value={consentimento.categoria.descricao}
                    />

                    <ConsentInfo
                        label={lang.tr('finalidade')}
                        value={consentimento.finalidade.descricao}
                    />

                    <ConsentInfo label={lang.tr('dados')}>
                        <ul className={'card-info'}>
                            {consentimento.categoria.metadados.map(
                                (metadado) => (
                                    <li className={metadado.sensivel ? "warning-after" : ''} key={metadado.id} >
                                        {metadado.rotulo}
                                    </li>
                                )
                            )}
                        </ul>
                    </ConsentInfo>

                    <ConsentInfo
                        label={lang.tr('data_criacao')}
                        value={stringUtils.formatDate(consentimento.dataHora)}
                        icon="fa fa-calendar-alt"
                    />
                    {consentimento?.dataExpiracao && (
                    <ConsentInfo
                        label={lang.tr('data_expiracao')}
                        value={stringUtils.formatDate(consentimento.dataExpiracao)}
                        icon="fa fa-calendar-alt"
                    />
                    )}

                    {consentimento.coletaTerceiro?.dataHoraColeta && (
                        <ConsentInfo
                            label={lang.tr('data_coleta')}
                            value={stringUtils.formatDate(
                                consentimento.coletaTerceiro.dataHoraColeta
                            )}
                            icon="fa fa-calendar-alt"
                        />
                    )}

                    {consentimento.coletaTerceiro?.formaColeta && (
                        <ConsentInfo
                            label={lang.tr('forma_coleta')}
                            value={
                                consentimento.coletaTerceiro.formaColeta.nome
                            }
                        />
                    )}

                    {consentimento.compartilhamentoDeDados && (
                        <ConsentInfo
                            label={lang.tr('uso_compartilhado_dados')}
                            value={consentimento.infoCompartilhamentoDeDados!}
                        />
                    )}

                    {consentimento.operador && (
                        <ConsentInfo
                            label={lang.tr('operador')}
                            value={`${stringUtils.formatCnpj(consentimento.operador.cnpj)} - ${consentimento.operador.nome}`}
                        />
                    )}

                    <ConsentInfo label={lang.tr('historico_atualizacoes')}>
                        <div
                            className="br-list shadow-sm-up"
                            style={{
                                borderRadius: '6px',
                                marginTop: '10px',
                                maxHeight: '30rem',
                                overflow: 'scroll'
                            }}
                        >
                            {renderHistory()}
                        </div>
                    </ConsentInfo>
                </div>

                <Typography htmlElement='span' weight="regular" className="mb-1n warning-before">{''}</Typography>
                <Typography htmlElement='span' weight="regular" className="mb-1n footnote">{lang.tr('info_dados_sensiveis')}</Typography>
            </Row>

            <ConsentCardFooter
                status={consentimento.status}
                idConsentimento={consentimento.id}
                onUpdate={onUpdate}
            />
        </BrCard>
    );
};

export default ConsentCard;
