
import { useState } from 'react';
import { BrButton, BrItem, BrTag, Icon, Row, Typography
} from '@govbr-ds/react-components';
import lang from '../../lang';
import { ConsentHistoryProps, getTagColor } from '.';
import { HistoricoConsentimento } from '../../types/dtos';
import '../../style/info.css';
import '../../style/list.css';
import stringUtils from '../../utils/stringUtils';

const ConsentHistory = ({ date, entries }: ConsentHistoryProps) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    const renderHistoryItem = (item: HistoricoConsentimento) => {
        return (
            <tr key={item.dataHora}>
                <td style={{textAlign: 'left'}}>
                    <span className={'info-text'} style={{marginRight: '20px'}}>{stringUtils.getTimeFromDate(item.dataHora)}</span>
                </td>
                <td style={{textAlign: 'right'}}>
                    <BrTag
                        color={getTagColor(item.status)}
                        value={lang.tr(item.status)}
                        type="text"
                    />
                </td>
            </tr>
        )
    }
  
    return (
        <BrItem
            className="br-item"
            divider={true}
            key={date}
            density={"media"}
        >
            <Row justifyContent={"between"}>
                <Typography htmlElement={"p"} weight={"bold"} className={"mb-1n"} margin={2}>
                    {date}
                </Typography>
                <BrButton onClick={toggleExpansion} circle={true}>
                    <Icon icon={isExpanded ? "fa fa-angle-up" : "fas fa-angle-down"}></Icon>
                </BrButton>
            </Row>

            {isExpanded && (
                <table style={{borderRadius: '20px', marginTop: '10px', width: '100%'}}>
                    <tbody>
                        <tr style={{color: 'black'}} key={date}>
                            <th style={{textAlign: 'left'}}>{lang.tr('hora')}</th>
                            <th style={{textAlign: 'right'}}>{lang.tr('status')}</th>
                        </tr>
                        {entries.map((item) => {
                            return renderHistoryItem(item)
                        })}
                    </tbody>
                </table>
            )}
        </BrItem>
    );
};

export default ConsentHistory;
